import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { AUTH_URL } from "../constants/api";

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";

let ENDPOINT = AUTH_URL;

const headers= {

};

// Login - get user token
export const loginUser = (userData,setButtonSpinner) => (dispatch) => {
  axios
    .post(ENDPOINT + "/login", userData,headers)
    .then((res) => {
      // Save to localStorage

      // Set token to localStorage
      const { token, user } = res.data.data;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("user", JSON.stringify(user));
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      //Reset Button Spinner
      setButtonSpinner(false);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      setButtonSpinner(false);
      if (err.response.data.status === "error") {
        if( err.response.data.message === "Unauthorized"){
          dispatch({
            type: GET_ERRORS,
            payload: "Unauthorized",
          });
        }else{
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data.message,
          });
        }
      } else if(err.response.data.status === "fail"){
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.data,
        });
      }
    });
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("user");
  axios
    .get(ENDPOINT + "/logout")
    .then((res) => {
      // Remove auth header for future requests
      setAuthToken(false);
      // Set current user to empty object {} which will set isAuthenticated to false
      dispatch(setCurrentUser({}));
      dispatch({
        type: GET_ERRORS,
        payload: "",
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
