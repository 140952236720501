import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import changeStateReducer from './changeStateReducer';

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  changeState: changeStateReducer
});
