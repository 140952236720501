export const LOCAL_URL = "http://127.0.0.1:8000";
// export const LOCAL_URL = "http://localhost";

export const STAGING_URL = "https://kyc-staging.aaa-equities.com.ph";

export const PRODUCTION_URL = "https://kyc.aaa-equities.com.ph";

export const API_URL = PRODUCTION_URL;

//Auth
export const AUTH_URL = `${API_URL}/api/auth`;
export const UAC_URL = `${API_URL}/api/users`;
export const ROLES_URL = `${API_URL}/api/roles`;

export const ACCOUNT_TYPES_URL = `${API_URL}/api/account_types`;
export const SOURCE_OF_FUNDS_URL = `${API_URL}/api/source_of_funds`;
export const TOTAL_ASSETS_URL = `${API_URL}/api/total_assets`;
export const ANNUAL_INCOME_URL = `${API_URL}/api/annual_income`;
export const AGENT_URL = `${API_URL}/api/agents`;
export const NET_WORTH_URL = `${API_URL}/api/net_worth`;
export const INVESTMENT_OBJECTIVE_URL = `${API_URL}/api/investment_objectives`;

export const CORPORATE_ACCOUNTS_URL = `${API_URL}/api/corporate_accounts`;
export const ACCOUNTS_URL = `${API_URL}/api/accounts`;

export const EXPORT_PDF = `${API_URL}/api/export_pdf`;
export const EXPORT_PDF_UPGRADE = `${API_URL}/api/export_pdf_upgrade`;

export const EXPORT_XLS = `${API_URL}/api/file-export`;
export const EXPORT_XLS_UPGRADE = `${API_URL}/api/upgrade-export`;
export const EXPORT_XLS_BY_AGENT = `${API_URL}/api/agents/`;

export const UPGRADE_ACCOUNTS_URL = `${API_URL}/api/upgrade_accounts`;

export const GET_NEW_SIGNUP_APPLICATIONS = `${API_URL}/api/charts/new_applications`;
export const GET_CURRENT_SIGNUP_STATUS = `${API_URL}/api/charts/current_signup_status`;

export const GET_NEW_UPGRADE_APPLICATIONS = `${API_URL}/api/charts/new_upgrade_applications`;
export const GET_CURRENT_UPGRADE_STATUS = `${API_URL}/api/charts/current_upgrade_status`;
